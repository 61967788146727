<template>
    <div>
        <main class="main-content">

            <!--== Start Blog Area Wrapper ==-->
            <section class="blog-area blog-grid-area">
            <div class="container">
                <div class="row" v-if="lookVal">
                    <div class="col-12">
                        <div class="job-details-wrap">
                            <div class="job-details-info">
                                <div class="thumb">
                                <img src="../../assets/img/picren.png" width="130" height="130" alt="Image-HasTech">
                                </div>
                                <div class="content">
                                <h1 class="title">{{this.dataList.username}}</h1>                                
                                <h5 class="sub-title">普通用户-L1</h5>
                                <div class="sub-text-box info-list">
                                    <div class="sub-text">Exp:100</div>
                                    <div class="sub-text">积分:100</div>
                                </div>
                                <div class="sub-text-box info-list">
                                    <div class="sub-text">ID:{{this.dataList.userids}}</div>
                                    <div class="sub-text">账号:{{this.dataList.accnumber}}</div>
                                    <div class="sub-text">Exp:100</div>
                                </div>
                                <ul class="info-list">
                                    <li><i class="icofont-location-pin"></i> 未知</li>
                                    <li><i class="icofont-phone"></i>{{this.dataList.phone}}</li>
                                    <li><i class="icofont-email"></i>{{this.dataList.email}}</li>
                                </ul>
                                </div>
                            </div>
                            <div class="job-details-price">
                                <button type="button" class="btn-theme btn-light" style="width:90px;" @click="undeveloped()">开通会员</button>&nbsp;
                                <button type="button" class="btn-theme" @click="updatePage()" style="width:90px;">修改信息</button> &nbsp;
                                 <button type="button" class="btn-theme" @click="uppassPage()" style="width:90px;">修改密码</button>
                            </div>
                        </div>
                    </div>   
                </div>
                <div class="row" v-if="updateVal">
                    <div class="col-12">     
                        <div class="updata-title">修改信息</div>                        
                        <div class="job-details-wrap">                         
                            <div class="job-details-info">                             
                                
                                <div class="content">
                                
                                    <!-- <div class="thumb">
                                        头像：
                                        <img src="../../assets/img/picren.png" width="130" height="130" alt="Image-HasTech" @click="undeveloped">
                                    </div> -->
                                    <div class="cont-box">
                                        <div class="cont-title">用户名：</div>
                                        <el-input v-model="updataList.username" placeholder="请输入内容"></el-input>
                                    </div>     
                                    <div class="cont-box">
                                        <div class="cont-title">手机号：</div>
                                        <el-input v-model="updataList.phone" placeholder="请输入内容"></el-input>
                                    </div> 
                                    <div class="cont-box">
                                        <div class="cont-title">邮箱：</div>
                                        <el-input v-model="updataList.email" placeholder="请输入内容"></el-input>
                                    </div>                      
                                
                                </div>
                            </div>
                            <div class="job-details-price">
                                <button type="button" class="btn-theme btn-light" style="width:100px;" @click="exitupdata()">退出修改</button>&nbsp;
                                <button type="button" class="btn-theme" @click="toUpdata()" style="width:100px;">提交修改</button>
                            </div>
                        </div>
                    </div>   
                </div>
                <div class="row" v-if="uppassVal">
                    <div class="col-12">     
                        <div class="updata-title">修改密码</div>                        
                        <div class="job-details-wrap">                         
                            <div class="job-details-info">                             
                                
                                <div class="content">
                                
                                    <!-- <div class="thumb">
                                        头像：
                                        <img src="../../assets/img/picren.png" width="130" height="130" alt="Image-HasTech" @click="undeveloped">
                                    </div> -->
                                    <div class="cont-box">
                                        <div class="cont-title">账号：</div>
                                        <el-input v-model="updataList.accnumber" :disabled="true" placeholder="请输入内容"></el-input>
                                    </div>     
                                    <div class="cont-box">
                                        <div class="cont-title">密码：</div>
                                        <el-input type="password" v-model="uppassList.password" placeholder="请输入内容"></el-input>
                                    </div> 
                                     <div class="cont-box">
                                        <div class="cont-title">新密码：</div>
                                        <el-input type="password" v-model="uppassList.newpass" placeholder="请输入内容"></el-input>
                                    </div> 
                                    <div class="cont-box">
                                        <div class="cont-title">确认新密码：</div>
                                        <el-input type="password" v-model="uppassList.newpassval" placeholder="请输入内容"></el-input>
                                    </div>                      
                                
                                </div>
                            </div>
                            <div class="job-details-price">
                                <button type="button" class="btn-theme btn-light" style="width:100px;" @click="exitupdata()">退出修改</button>&nbsp;
                                <button type="button" class="btn-theme" @click="toUpPass()" style="width:100px;">提交修改</button>
                            </div>
                        </div>
                    </div>   
                </div>
                <div>
                    <div class="record-title" v-if="recordList.length>=10">下载记录（近10条）</div>
                    <div class="record-title" v-else>下载记录</div>                    
                        <div class="col-md-6 col-sm-12" v-for="(item,index) in recordData" :key="index">
                            <el-card class="box-card" style="margin:10px;">
                                <div class="job-details-info">
                                    <div class="thumb">
                                        <img :src="item.pImage" width="130" height="130" alt="Image-HasTech" v-if="item.pImage">
                                        <img src="../../assets/img/nopic.png" width="130" height="130" alt="Image-HasTech" v-if="!item.pImage">
                                    </div>
                                    <div class="content">                                       
                                    <h1 class="title">{{item.name}}</h1>                            
                                    
                                    <div class="sub-text-box info-list">
                                        <div class="sub-text">素材分类:</div>
                                        <div class="sub-title" style="cursor: pointer;" v-if="item.assets==1" @click="toModOf('word')">办公文档——Word</div>
                                        <div class="sub-title" style="cursor: pointer;" v-if="item.assets==2" @click="toModOf('excel')">数据表格－Excel</div>
                                        <div class="sub-title" style="cursor: pointer;" v-if="item.assets==3" @click="toModOf('ppt')">幻灯片－PPT</div>
                                        <div class="sub-title" style="cursor: pointer;" v-if="item.assets==4" @click="toModOf('pic')">广告设计</div>
                                        <div class="sub-title" style="cursor: pointer;" v-if="item.assets==5">资源共享区</div>
                                    </div>  
                                    <h5 class="sub-title">下载时间：{{recordList[index].downdate}}</h5>  
                                    <button type="button" class="btn-theme" @click="toModList(item.id)" style="width:150px;">查看模板</button>
                                    </div>            
                                                                    
                                </div>
                            </el-card>
                        </div>
                        <div v-if="recordData.length===0" class="noRecord">暂无下载记录</div>
                    
                </div>
            </div>
                                                  
            </section>
            <!--== End Blog Area Wrapper ==-->
            
        </main>
    </div>
</template>
<script>
import { mixin } from '../../mixins'
import {getUserOfAccnumber,updateUserMsg,RecordByDownuser,selectWordByPrimaryKey,getUpPass} from '../../api/index'
export default {
    mixins: [mixin],
    data(){
        return{
            dataList:'',
            updataList:{username:'',phone:'',email:''},
            uppassList:{password:'',newpass:'',newpassval:''},
            recordList:[],//下载记录
            recordData:[], //下载记录中每个下载模板的信息
            lookVal:true,
            updateVal:false,
            uppassVal:false
        }
    },
    created(){
         this.selUsers();
                    
    },
    watch: {			
       
        
    },
    methods:{
        //切换至修改
        updatePage(){
                this.updateVal=true
                this.lookVal=false
        },
        //切换至修改密码
        uppassPage(){
            
            this.lookVal=false
            this.updateVal=false
            this.uppassVal=true
        },
        //切换至查看信息
        exitupdata(){
            this.$message('草稿箱保存了您未提交的信息！');
            this.updateVal=false
            this.uppassVal=false
            this.lookVal=true
        },
        record(){
            RecordByDownuser(this.dataList.accnumber).then(list => {
                this.recordList=[]
                this.recordData=[]
                this.recordList=list
                if(this.recordList.length>10){
                    this.recordList=this.recordList.slice(0, 10);
                }
                this.recordList.forEach(item=>{
                    selectWordByPrimaryKey(item.wid).then(res => {
                        this.recordData.push(res)
                })
                })              
            })
        },
        toUpPass(){
            let params = new URLSearchParams()
            params.append('id', this.updataList.userids)
            params.append('accnumber', this.updataList.accnumber)
            params.append('password', this.uppassList.password)
            params.append('newpass', this.uppassList.newpass)
            getUpPass(params).then(res => {
                if (res.code === 1) {
                    this.selUsers()                    
                    this.$message({
                        showClose: true,
                        message: '密码修改成功。下次登录时，记得使用新密码！',
                        type: 'success'
                    });
                    this.lookVal=true
                    this.uppassVal=false                 
                } else {
                    if(res.code === 2){
                        this.$message({
                            showClose: true,
                            message: '密码输入错误，请重新输入',
                            type: 'error'
                        });
                    }else{
                        this.$message({
                        showClose: true,
                        message: '修改失败，请稍后再试！',
                        type: 'error'
                        });
                    }                   
                }
                })
                .catch(err => {
                console.log(err)
                })
                
        },
        toUpdata(){
            let params = new URLSearchParams()
            params.append('id', this.updataList.userids)
            params.append('username', this.updataList.username)
            params.append('pic', this.updataList.pic || '')
            params.append('phone', this.updataList.phone || '')
            params.append('email', this.updataList.email || '')
            params.append('remarks', this.updataList.remarks || '')
            params.append('state', this.updataList.state || '')
            console.log(this.updataList.state);
            updateUserMsg(params)
                .then(res => {
                if (res.code === 1) {
                    this.selUsers()                    
                    this.$message({
                        showClose: true,
                        message: '修改成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        showClose: true,
                        message: '修改失败，请稍后再试！',
                        type: 'error'
                    });
                }
                })
                .catch(err => {
                console.log(err)
                })
                this.updateVal=false
                this.lookVal=true
        },
        // 获取当前页
        async selUsers() {
       let res= await getUserOfAccnumber(window.sessionStorage.getItem("name"));
       this.dataList=res
      this.updataList=JSON.parse(JSON.stringify(res));
        this.record()    
      
    },
    toModOf(val){
        this.$router.push({path: '/ModsOf', query: {key:val}})
    },
    toModList(val){
        this.$router.push({path: '/ModList',  query: {id:val}})
    }
    }
}
</script>
<style lang="less" scoped>
    .sub-text-box{
        display: flex;
        padding-bottom: 5px;
    }
    .sub-text{
        padding-right: 10px;
    }
    .updata-title{
        text-align: center;
        font-size: 30px;
    }
    .cont-box{
        display: flex;
        width: 300px;
        padding: 20px 0;
    }
    .cont-title{
        padding-top: 10px;
        width: 100px;
    }
    .record-title{
        font-size: 30px;
        text-align: center
    }
    .record-box{
        display: flex;
    }
    .record-conts-box{
        height: 100px;
        background: #999;

        margin: 0 10px 10px;
    }
    .noRecord{
        text-align: center;
        font-size: 15xp;
        color: #999;
    }
    /deep/.el-pagination.is-background .el-pager li:not(.disabled) {
       color: #999;
      border-radius: 5px;
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
 /deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
      background-color: #03A84E;
      color: #fff;//修改选中后的样式
    }
//上一页和下一页的样式
    /deep/.el-pagination.is-background .btn-next{
      //margin: 0 5px;
      background-color: #03A84E;
      color: #fff;
      min-width: 60px;
      height: 60px;
      border-radius: 5px;
    }
    /deep/.el-pagination.is-background .btn-prev{
      //margin: 0 5px;
     background-color: #03A84E;
      color: #fff;
      min-width: 60px;
      height: 60px;
      border-radius: 5px;
    }
   /deep/.el-pagination.is-background .el-pagination__jump{

      height: 60px;
      line-height: 60px;
   }
</style>